import { useParams } from 'react-router-dom';
import wurd from 'wurd-react';
import { useQuery, useInfiniteQuery } from 'react-query';

import * as actions from 'actions';

import Split from 'components/split-page';
import Progress from 'components/unit-progress';
import SiteCard from 'components/site-detail/site-card';
import UnitTypeList from 'components/site-detail/unit-type-list';
import Title from 'components/title';
import Loader from 'components/loader';


const cms = wurd.block('siteDetail');

export default function SiteDetail() {
  const { site: siteCode } = useParams();
  const limit = Number(new URLSearchParams(location.search).get('limit')) || 250; // TODO: Make much lower once leanSettings enabled

  // Load site
  const { data: site, ...siteQuery } = useQuery(['sites', siteCode], () => actions.sites.get(siteCode));

  // Load unit types
  const unitTypesInfiniteQuery = useInfiniteQuery(`site-${siteCode}-unitTypes`, function ({ pageParam: offset = 0 }) {
    return actions.unitTypes.fetch(siteCode, {
      offset,
      limit,
    });
  }, {
    getNextPageParam: function (lastPage, allPages) {
      return lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0);
    },
  });

  const unitTypes = unitTypesInfiniteQuery.data?.pages.flat(1);

  if (siteQuery.isLoading) return <Loader />;

  if (!site) return <Title cms={cms.block('notFound')} className="mt-5 text-center" />;

  return (
    <>
      <Progress site={site} />

      <Split className="mb-2">
        <div className="flex-1"><SiteCard site={site} showFeatures /></div>
        <div className="flex-1 px-2 px-sm-0 mt-lg-2">
          <UnitTypeList
            site={site}
            unitTypes={unitTypes}
            infiniteQuery={unitTypesInfiniteQuery}
          />
        </div>
      </Split>
    </>
  );
}
