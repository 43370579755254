import wurd from 'wurd-react';
import api from '../utils/api';
import analytics from '../utils/analytics';
import store from '../store';
import errHandler from './_err-handler';
import processCustomFieldsData from '../plugins/custom-fields/actions';


function btoaFixed(str) {
  const bytes = new TextEncoder().encode(str);
  return window.btoa(String.fromCharCode(...bytes));
}

function setUser(user) {
  const settings = store.get('settings');

  store.set({ user, settings: { ...settings, ...user.settings } });
}

const includeOpts = 'settings,billing,units,valetOrders,items,customFields,activeJobs';

export function fetch(opts) {
  return api.get(`/v1/user?include=${includeOpts}`)
    .then((user) => {
      setUser(user);

      if (opts?.initial) analytics.identify(user);

      return user;
    })
    .catch(err => {
      store.set({
        user: null,
        ...opts?.initial && { googleClientId: err.headers?.['x-google-client-id'] },
      });
      errHandler(err);
    });
}


export function signin({ email, password }) {
  const apiConfig = {
    headers: {
      'Authorization': 'Basic ' + btoaFixed(email + ':' + password),
      'X-Requested-With': 'XMLHttpRequest', // Prevents Safari displaying BasicAuth popup)
    },
  };

  // Login and preload user data
  return api.post(`/v1/auth/token?include=${includeOpts}`, {}, apiConfig)
    .then(({ user }) => {
      const settings = store.get('settings');

      // When user is signed in, make sure to remove market param or to reset path if marketId changed (to clear the order summary that might have different stuff from the user's market)
      const sp = new URLSearchParams(window.location.search);
      if (window.location.pathname.endsWith('/valet') && settings.marketId !== user.settings.marketId) {
        window.alert(wurd.text('common.actions.user.redirectMarket'));
        sessionStorage.removeItem('valet-order');
        window.location = window.location.pathname;
      }

      setUser(user);

      analytics.identify(user, 'password');

      return user;
    })
    .catch(errHandler);
}

export function logout() {
  return api.post('/v1/auth/logout')
    .then(() => {
      store.set({ user: null });
    })
    .catch(errHandler)
}

export function sendForgotPasswordEmail(data) {
  return api.post('/v1/auth/forgot-password', data)
    .catch(errHandler);
}

export function sendConfirmEmail(email) {
  return api.post('/v1/auth/confirm-email', { email });
}

export function resetPassword(data) {
  return api.post('/v1/auth/reset-password', data)
    .catch(errHandler);
}

export async function update(data) {
  const settings = store.get('settings');
  if (data.customFields) {
    data.customFields = await processCustomFieldsData(data.customFields, settings.userCustomFields);
  }

  return api.put(`/v1/user?include=${includeOpts}`, data)
    .then((user) => {
      setUser(user);

      return user;
    })
    .catch(errHandler);
}

export async function signup(data) {
  const settings = store.get('settings');

  if (settings.captcha === 'recaptcha') {
    await window.grecaptcha.execute();
    data.recaptchaResponse = window.grecaptcha.getResponse();
  }

  if (data.customFields) {
    data.customFields = await processCustomFieldsData(data.customFields, settings.userCustomFields);
  }

  const { market } = Object.fromEntries(new URLSearchParams(window.location.search));

  return api.post('/v1/users', { ...data, marketId: market, language: store.get('lang') })
    .then(() => signin(data))
    .then(user => {
      analytics.signedUp(user);
      return user;
    })
    .catch(errHandler);
}

/**
 * Updates the user's preferred language
 *
 * @param {String} code   e.g. 'en'
 */
export async function setLanguage(code) {
  // Save for next time
  localStorage.setItem('lang', code);

  // Save to user if logged in
  const { user } = store.get();

  if (user) {
    await api.put('/v1/user', { language: code });
  }

  // Reload
  window.location.reload();
}
