import store from '../store';
import wurd from 'wurd-react';
import styled from '@emotion/styled';
import { useInfiniteQuery } from 'react-query';

import useGeolocation from 'utils/useGeolocation';
import * as actions from 'actions';

import SiteCard from 'components/site-list/site-card';
import Progress from 'components/unit-progress';
import SiteMap from 'components/site-map';
import Title from 'components/title';
import Loader from 'components/loader';
import ListLoadMoreButton from 'components/list-load-more-button';

const cms = wurd.block('selfStorage');

const HalfWidthDesktop = styled.div({
  top: 57, // header
  '@media (min-width: 992px)': {
    width: 'calc(50vw - 8px)'
  }
});

export default function SiteList() {
  const settings = store.get('settings');
  const user = store.get('user');
  const userLocation = useGeolocation();
  const limit = Number(new URLSearchParams(location.search).get('limit')) || 50;

  const infiniteQuery = useInfiniteQuery('sites', ({ pageParam: offset = 0 }) => actions.sites.fetch(`?${new URLSearchParams({ offset, limit })}`), {
    getNextPageParam: (lastPage, allPages) => lastPage.length < limit ? undefined : allPages.reduce((a, p) => a + p.length, 0),
  });

  const sites = infiniteQuery.data?.pages.flat(1);

  if (!sites) return <Loader />;

  return (
    <>
      <Progress />

      <div className="container">
        <div className="row gx-0">
          <div className="col-lg-6 order-lg-2 px-0">
            <HalfWidthDesktop className="position-sticky">
              <SiteMap sites={sites} />
            </HalfWidthDesktop>
          </div>
          <div className="col-lg-6 pe-lg-2 pt-2 pt-xs-5">
            <Title cms={cms.block('title')} className="d-none d-lg-block" />

            <ul className="list-unstyled mt-2 row">
              {sites.map(site => {
                return (
                  <li key={site.id} className="mb-2">
                    <SiteCard
                      site={site}
                      cms={cms}
                      userLocation={userLocation}
                    />
                  </li>
                );
              })}
            </ul>

            <ListLoadMoreButton
              infiniteQuery={infiniteQuery}
            />
          </div>
        </div>
      </div >
    </>
  );
}
