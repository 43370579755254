import loadScript from 'load-script2';
import { useQuery } from 'react-query';
import store from 'store';


export default function Recaptcha() {
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  if (!siteKey) throw new Error('Missing REACT_APP_RECAPTCHA_SITE_KEY environment variable');

  const recaptchaQuery = useQuery('recaptcha', () => loadScript('https://www.google.com/recaptcha/api.js', { nonce: store.get('nonce') }));

  return (
    <div
      id="recaptcha"
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-size="invisible"
    />
  );
}