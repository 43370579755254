import wurd from 'wurd-react';

import UnitTypeCard from './unit-type-card';
import Empty from 'components/empty';
import Title from 'components/title';
import ListLoadMoreButton from 'components/list-load-more-button';

const cms = wurd.block('siteDetail');

export default function SiteDetail_UnitTypeList({
  site,
  unitTypes,
  infiniteQuery,
}) {
  if (!unitTypes || unitTypes.length === 0) return <Empty cms={cms.block('empty')} />;

  return (
    <>
      <Title cms={cms.block('types')} className="px-5" />

      {/* <div className="text-muted fw-light my-4">
        <cms.Markdown id="infos" vars={{ site: getLangText(site.title) }} />
      </div> */}

      <ul className="list-unstyled d-flex flex-column gap-3 mt-5">
        {unitTypes.map(unitType => (
          <li key={unitType.id}>
            <UnitTypeCard unitType={unitType} site={site} />
          </li>
        ))}
      </ul>

      <ListLoadMoreButton
        infiniteQuery={infiniteQuery}
      />
    </>
  );
}
